import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Tracking from '../containers/tracking/tracking';
import NavBar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';

const TrackingPage: React.FC = (): JSX.Element =>
  <Layout>
    <Seo title='Werde ich getrackt?' noIndex />
    <NavBar />
    <Tracking />
    <Footer />
  </Layout>;

export default TrackingPage;
