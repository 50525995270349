import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const TrackingCardContainer = styled.div`
  ${breakpoints.xs`
    width: 300px;
    margin-bottom: 230px;
    `}
  ${breakpoints.sm`
    width: 350px;
    `}
  ${breakpoints.md`
    width: 410px;
    margin-bottom: 170px;
    `}
  background-image: linear-gradient(243deg, #fdfdfd 100%, rgba(250, 250, 250, 0.3) 0%);
  box-shadow: 0 2px 10px 1px rgba(57, 116, 128, 0.3);
  border-radius: 10px;
  height: auto;
  margin-top: 75px;
`;

const ContentWrapper = styled.div`
  ${breakpoints.xs`
    height: 410px;
  `}
  ${breakpoints.md`
    height: 500px;
  `}
  display: flex;
  flex-flow: column;
  padding: 0 25px;
  align-items: center;
  justify-content: center;
`;

const Dot = styled.div < { isIgnored: boolean | ((value: (arg0: any) => any) => void) } >`
  height: 45px;
  width: 45px;
  margin-top: 30px;
  margin-bottom: 35px;
  border-radius: 50%;
  display: inline-block;
  transition: 0.6s ease-in-out;
  background-color: ${({ theme, isIgnored }) => (isIgnored ? '#bbef6b' : theme.color.butterscotch.main)};
`;

const ExcludeIncludeText = styled.h2 < { isIgnored: boolean | ((value: (arg0: any) => any) => void) } >`
  margin-bottom: 20px;
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  font-family: ${({ theme }) => theme.typography.mosk.bold};
  color: ${({ theme }) => theme.color.basic.black};

  > span {
    color: ${({ theme, isIgnored }) => (isIgnored ? '#bbef6b' : theme.color.butterscotch.main)};
    transition: 0.5s ease-in-out;
  }
  ${breakpoints.xs`
    font-size: 27px;
    font-weight: 800;
    `}
  ${breakpoints.md`
    font-size: 32px;
    font-weight: 700;
    `}
`;

const Description = styled.p`
  ${breakpoints.xs`
    font-size: 15px;
  `}
  ${breakpoints.md`
    font-size: 17px;
  `}
  width: 300px;
  line-height: 1.56;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ theme }) => theme.color.basic.black};
  font-family: ${({ theme }) => theme.typography.akkurat};
`;

export {
  TrackingCardContainer,
  Dot,
  ExcludeIncludeText,
  Description,
  ContentWrapper,
};
