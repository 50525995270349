import React from 'react';

import { ButtonOutlined } from '../buttons/index';
import { TrackingCardContainer, Dot, ExcludeIncludeText, Description, ContentWrapper } from './components';

type TrackingCardProps = {
  isIgnored: boolean | ((value: (arg0: any) => any) => void),
  onButtonClick: () => void,
};

const TrackingCard: React.FC<TrackingCardProps> = ({ isIgnored, onButtonClick }) =>
  <TrackingCardContainer >
    <ContentWrapper>
      <ExcludeIncludeText isIgnored={isIgnored}>
        Du bist vom Tracking
        {' '}
        <span>{isIgnored ? ' ausgeschlossen' : ' nicht ausgeschlossen'}</span>
      </ExcludeIncludeText>
      <Description>
        Für unsere Statistik ist es wichtig, unsere Crew vom Tracking
        auszuschließen. Das Lämpchen unterhalb signalisiert, ob du ausgeschlossen
        bist oder nicht.
      </Description>
      <Dot isIgnored={isIgnored} />
      <ButtonOutlined
        type='button'
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={onButtonClick}
        primary
      >
        {isIgnored ? 'TRACK MICH' : 'TRACK MICH NICHT' }
      </ButtonOutlined>
    </ContentWrapper>
  </TrackingCardContainer>;

export default TrackingCard;
