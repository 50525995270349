import React from 'react';

import useLocalStorage from '../../hooks/use-localstorage';
import useHasMounted from '../../hooks/use-has-mounted';
import PositionMainRelative from '../../styles/position-main-relative';
import TrackingCard from '../../components/tracking-card/tracking-card';
import { BackgroundDarken, BackgroundSilver } from '../../components/components';
import isBrowser from '../../helpers/is-browser';
import { Container, Headline, SmallerHeadline } from './components';

const Tracking: React.FC = () => {
  const [isIgnored] = useLocalStorage('plausible_ignore', false);
  const hasMounted = useHasMounted();

  if (!hasMounted) {
    return null;
  }

  const handleButtonClick = () => {
    if (!isBrowser() || typeof isIgnored !== 'boolean') {
      return;
    }
    window.localStorage.setItem('plausible_ignore', JSON.stringify(!isIgnored));
  };
  return (
    <BackgroundSilver >
      <BackgroundDarken>
        <PositionMainRelative />
        <Container>
          <Headline>Tracking Exclude</Headline>
          <SmallerHeadline>PLAUSIBLE OPT OUT FÜR DIE CREW</SmallerHeadline>
          <TrackingCard isIgnored={isIgnored} onButtonClick={handleButtonClick} />
        </Container>
      </BackgroundDarken>
    </BackgroundSilver>
  );
};

export default Tracking;
