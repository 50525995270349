import styled from 'styled-components';

import breakpoints from '../../styles/breakpoints';
import { PitchHeadline, SmallHeadline } from '../../components/headlines/index';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Headline = styled(PitchHeadline)`
  ${breakpoints.xs`
    font-size: 32px;
    `}
  ${breakpoints.sm`
    font-size: 36px;
    `}
  ${breakpoints.md`
    font-size: 50px;
    `}
  ${breakpoints.lg`
    font-size: 60px;
    `}
  height: unset;
  width: unset;
  margin-top: 60px;
  margin-bottom: 20px;
`;

const SmallerHeadline = styled(SmallHeadline)`
  ${breakpoints.xs`
    font-size: 16px;
    width: 250px;
  `}
  ${breakpoints.xs`
    font-size: 18px;
  `}
  ${breakpoints.md`
    font-size: 20px;
    width: auto;
  `}
  ${breakpoints.lg`
    font-size: 24px;
  `}
`;

export {
  Container,
  Headline,
  SmallerHeadline,
};
